export function stringCode2scriptCode(s: string): string {
  let r = s;
  // r = r.replace(/function\s+\w+\s*\([^)]*\)\s*\{([^}]*)\}/g, "$1");
  // ※ 親要素のfunctionだけが対象
  r = r.replace(/function\s+\w+\s*\(\)\s*\{([\s\S]*?)\}/, 'function() {$1}');
  // xxxx r = r.replace(/<my-hl>(.+?)<\/my-hl>\n/gs, "$1")
  r = r.replace(/<my-hl>/g, "")
  r = r.replace(/<\/my-hl>/g, "")
  return r;
}
