<template>
  <div class="main01 notfound">
    <div>
      <h1>404 - ページが見つかりません</h1>
      <div class="">
        お探しのページは存在しないか、<br />削除された可能性があります。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>