<template>
  <div class="main01 reference css">
    <h1 class="midasi text-xl">CSSリファレンス</h1>
    <Reference :list="list" />
    <div class="examples">
      <!-- --------------------
        background
      -------------------- -->
      <ExampleTemp :id="'background'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="background-example">
  <div class="box-background highlight">
    背景色を指定します
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.background-example {
  .box-background {
    width: 200px;
    padding: 16px;
    margin: 16px auto;
    <Marker>background: pink;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       background-color
      -------------------- -->
      <ExampleTemp :id="'background-color'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="background-color-sample highlight">
  背景色を指定します
</div>
</pre>
        </template>
        <template #style>
<pre>
.background-color-sample {
  width: 200px;
  padding: 16px;
  margin: 16px auto;
  <Marker>background-color: pink</Marker>;
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        font-weight
      -------------------- -->
      <ExampleTemp :id="'font-weight'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="font-weight-sample">
  <span class="font-weight1 highlight">文字の太さ(標準: 400）</span><br>
  <span class="font-weight2 highlight">文字の太さ(一般: 700）</span><br>
  <span class="font-weight3 highlight">文字の太さ(相対的に一段階細く)</span><br>
  <span class="font-weight4 highlight">文字の太さ(相対的に一段階太く)</span><br>
  <span class="font-weight100 highlight">文字の太さ(100)</span><br>
  <span class="font-weight200 highlight">文字の太さ(200)</span><br>
  <span class="font-weight300 highlight">文字の太さ(300)</span><br>
  <span class="font-weight400 highlight">文字の太さ(400)</span><br>
  <span class="font-weight500 highlight">文字の太さ(500)</span><br>
  <span class="font-weight600 highlight">文字の太さ(600)</span><br>
  <span class="font-weight700 highlight">文字の太さ(700)</span><br>
  <span class="font-weight800 highlight">文字の太さ(800)</span><br>
  <span class="font-weight900 highlight">文字の太さ(900)</span>
</div>
</pre>
        </template>
        <template #style>
<pre>
.font-weight-sample {
  .font-weight1 {
    <Marker>font-weight: normal;</Marker>
  }
  .font-weight2 {
    <Marker>font-weight: bold;</Marker>
  }
  .font-weight3 {
    <Marker>font-weight: lighter;</Marker>
  }
  .font-weight4 {
    <Marker>font-weight: bolder;</Marker>
  }
  .font-weight100 {
    <Marker>font-weight: 100;</Marker>
  }
  .font-weight200 {
    <Marker>font-weight: 200;</Marker>
  }
  .font-weight300 {
    <Marker>font-weight: 300;</Marker>
  }
  .font-weight400 {
    <Marker>font-weight: 400;</Marker>
  }
  .font-weight500 {
    <Marker>font-weight: 500;</Marker>
  }
  .font-weight600 {
    <Marker>font-weight: 600;</Marker>
  }
  .font-weight700 {
    <Marker>font-weight: 700;</Marker>
  }
  .font-weight800 {
    <Marker>font-weight: 800;</Marker>
  }
  .font-weight900 {
    <Marker>font-weight: 900;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        text-align
      -------------------- -->
      <ExampleTemp :id="'text-align'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="text-align-sample">
  <div>text-align: start;</div>
  <p class="text-align1 highlight">
  text-align: startを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: end;</div>
  <p class="text-align2 highlight">
  text-align: endを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: left;</div>
  <p class="text-align3 highlight">
  text-align: leftを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: center;</div>
  <p class="text-align4 highlight">
  text-align: centerを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: right;</div>
  <p class="text-align5 highlight">
  text-align: rightを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: justify;</div>
  <p class="text-align6 highlight">
  text-align: justifyを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: justify-all;</div>
  <p class="text-align7 highlight">
  text-align: justify-allを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p>
  <div>text-align: match-parent;</div>
  <p class="text-align8 highlight">
  text-align: match-parentを設定しました。<br /><br />
  コンテンツはボックス内でどのように配置されますか?
  How is the content arranged in the box?
  </p> 
</div>
</pre>
        </template>
        <template #style>
<pre>
.text-align-sample {
  p {
    background-color:#99cc00;
    width:240px;
    margin-left: auto;
    margin-right: auto;
  }
  .text-align1 {
    <Marker>text-align:start;</Marker>
  }
  .text-align2 {
    <Marker>text-align: end;</Marker>
  }
  .text-align3 {
    <Marker>text-align: left;</Marker>
  }
  .text-align4 {
    <Marker>text-align: center;</Marker>
  }
  .text-align5 {
    <Marker>text-align: right;</Marker>
  }
  .text-align6 {
    <Marker>text-align:justify;</Marker>
  }
  .text-align7 {
    <Marker>text-align:justify-all;</Marker>
  }
  .text-align8 {
    <Marker>text-align:match-parent;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        vertical-align
      -------------------- -->
      <ExampleTemp :id="'vertical-align'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true" :isnocode="true">
        <template #template>
<pre>
<div class="vertical-align-sample1">
  <div class="c" style="padding:0; margin:0; height:10rem; font-size:50pt;">
    <span style="font-size:80pt; border:1px solid gray; margin-right:5px;">高xy</span><span style="border:1px solid gray;">基準xy<sup>2</sup><sub>2</sub></span>
    <span style="font-size:9pt; vertical-align:top; margin-left:-10px;">←top</span>    
    <span style="font-size:9pt; vertical-align:text-top; margin-left:-65px;">←text-top</span>
    <span style="font-size:9pt; vertical-align:super; margin-left:-97px;">←super</span>  
    <span style="font-size:9pt; vertical-align:middle; margin-left:-76px;">←middle</span>
    <span style="font-size:9pt; vertical-align:baseline; margin-left:-83px;">←baseline</span>
    <span style="font-size:9pt; vertical-align:text-bottom; margin-left:-96px;">←text-bottom</span>
    <span style="font-size:9pt; vertical-align:sub; margin-left:-30px;">←sub</span>
    <span style="font-size:9pt; vertical-align:bottom; margin-left:-150px;">←bottom</span>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.vertical-align-sample1 {
  display: none;
  @media(min-width: 768px) {
    display: block;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <ExampleTemp :list="list" :pdfNextPageShow="true" :isnospacemargintop="true">
        <template #template>
<pre>
<div class="vertical-align-sample2">
  <div class="vertical-align0">
    <h3>
      ● vertical-alignなし
    </h3>
    <div>
    デフォルトは<span class="highlight">テキスト</span>下揃え
    </div>
  </div>
  <div class="vertical-align1">
    <h3>
      ● vertical-align: top;
    </h3>
    <div>
    topの<span class="highlight">テキスト</span>は、一番高いテキストの上端揃え
    </div>
  </div>
  <div class="vertical-align2">
    <h3>
      ● vertical-align: text-top;
    </h3>
    <div>
    text-topの<span class="highlight">テキスト</span>は、基準テキストの上端揃え
    </div>
  </div>
  <div class="vertical-align3">
    <h3>
      ● vertical-align: super;
    </h3>
    <div>
    superの<span class="highlight">テキスト</span>は、標準テキストの上付文字のベースライン揃え
    </div>
  </div>
  <div class="vertical-align4">
    <h3>
      ● vertical-align: middle;
    </h3>
    <div>
      middleの<span class="highlight">テキスト</span>は、中央揃え
    </div>
  </div>
  <div class="vertical-align5">
    <h3>
      ● vertical-align: baseline;
    </h3>
    <div>
      baselineの<span class="highlight">テキスト</span>は、基準テキストのベースライン揃え
    </div>
  </div>
  <div class="vertical-align6">
    <h3>
      ● vertical-align: text-bottom;
    </h3>
    <div>
      text-bottomの<span class="highlight">テキスト</span>は、基準テキストの下端揃え
    </div>
  </div>
  <div class="vertical-align7">
    <h3>
      ● vertical-align: sub;
    </h3>
    <div>
      subの<span class="highlight">テキスト</span>は、標準テキストの下付文字のベースライン揃え
    </div>
  </div>
  <div class="vertical-align8">
    <h3>
      ● vertical-align: bottom;
    </h3>
    <div>
      bottomの<span class="highlight">テキスト</span>は、その行で一番低いテキストの下端揃え
    </div>
    <div>
      画像<img class="highlight" src="/apple.jpg" alt="アイコン">を下揃えに配置しました。
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.vertical-align-sample2 {
  span {
    font-size: 32px;
  }
  img {
    display: inline-block;
    width: 20%;
    height: 20%;
  }
  .vertical-align0 {
    margin-bottom: 32px;
  }
  .vertical-align1 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: top;</Marker>
    }
  }
  .vertical-align2 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: text-top;</Marker>
    }
  }
  .vertical-align3 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: super;</Marker>
    }
  }
  .vertical-align4 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: middle;</Marker>
    }
  }
  .vertical-align5 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: baseline;</Marker>
    }
  }
  .vertical-align6 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: text-bottom;</Marker>
    }
  }
  .vertical-align7 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: sub;</Marker>
    }
  }
  .vertical-align8 {
    margin-bottom: 32px;
    span {
      <Marker>vertical-align: bottom;</Marker>
    }
    img {
      margin-top: 16px;
      <Marker>vertical-align: bottom;</Marker>
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        line-height
      -------------------- -->
      <ExampleTemp :id="'line-height'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="line-height-sample">
  <p class="line-height1">
    <span class="highlight">これは行間が1.5倍に設定された例です。これは行間が1.5倍に設定された例です。これは行間が1.5倍に設定された例です。これは行間が1.5倍に設定された例です。これは行間が1.5倍に設定された例です。</span>
  </p>
  <p class="line-height2">
    <span class="highlight">これは行間が2倍に設定された例です。これは行間が2倍に設定された例です。これは行間が2倍に設定された例です。これは行間が2倍に設定された例です。これは行間が2倍に設定された例です。</span>
  </p>
  <p class="line-height11">
    <span class="highlight">これは行間が14pxに設定された例です。これは行間が14pxに設定された例です。これは行間が14pxに設定された例です。これは行間が14pxに設定された例です。これは行間が14pxに設定された例です。これは行間が14pxに設定された例です。</span>
  </p>
  <p class="line-height12">
    <span class="highlight">これは行間が22pxに設定された例です。これは行間が22pxに設定された例です。これは行間が22pxに設定された例です。これは行間が22pxに設定された例です。これは行間が22pxに設定された例です。これは行間が22pxに設定された例です。</span>
  </p>
  <p class="line-height13">
    <span class="highlight">これは行間が28pxに設定された例です。これは行間が28pxに設定された例です。これは行間が28pxに設定された例です。これは行間が28pxに設定された例です。これは行間が28pxに設定された例です。これは行間が28pxに設定された例です。</span>
  </p>
  <p class="line-height14">
    <span class="highlight">これは行間が34pxに設定された例です。これは行間が34pxに設定された例です。これは行間が34pxに設定された例です。これは行間が34pxに設定された例です。これは行間が34pxに設定された例です。これは行間が34pxに設定された例です。</span>
  </p>
</div>
</pre>
        </template>
        <template #style>
<pre>
.line-height-sample {
  p.line-height1 {
    <Marker>line-height: 1.5; /* 行と行の間隔を1.5倍にする */</Marker>
  }
  p.line-height2 {
    <Marker>line-height: 2; /* 行と行の間隔を2倍にする */</Marker>
  }
  p.line-height11 {
    <Marker>line-height: 14px;</Marker>
  }
  p.line-height12 {
    <Marker>line-height: 22px;</Marker>
  }
  p.line-height13 {
    <Marker>line-height: 28px;</Marker>
  }
  p.line-height14 {
    <Marker>line-height: 34px;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>

      <!-- --------------------
        width
      -------------------- -->
      <ExampleTemp :id="'width'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="width-sample">
  <div class="width-box w-50percent highlight">
    幅を親要素の50%に指定します
  </div>
  <div class="width-box w-100px highlight">
    幅を100pxに指定します
  </div>
  <div class="width-box w-auto highlight">
    幅を自動で指定します
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.width-sample {
  .width-box {
    padding: 16px;
    margin: 16px auto;
    background-color: pink;
  }
  .w-50percent {
    <Marker>width: 50%;</Marker>
  }
  .w-100px {
    <Marker>width: 100px;</Marker>
  }
  .w-auto {
    <Marker>width: auto;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        height
      -------------------- -->
      <ExampleTemp :id="'height'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="height-sample">
  <div class="h-50percent-wrap">
    親要素(高さ: 100px)
    <div class="h-50percent highlight">
      子要素(高さ: 親要素の50%)
    </div>
  </div>
  <div class="h-100px highlight">
    高さを100pxに指定します
  </div>
  <div class="h-auto highlight">
    高さを自動で指定します
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.height-sample {
  div {
    padding: 16px;
    margin: 16px auto;
    background-color: pink;
  }
  > div {
    margin-bottom: 16px;
  }
  .h-50percent-wrap {
    background-color: green;
    .h-50percent {
      <Marker>height: 50%;</Marker>
    }
  }
  .h-100px {
    <Marker>height: 100px;</Marker>
  }
  .h-auto {
    <Marker>height: auto;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        box-sizing
      -------------------- -->
      <ExampleTemp :id="'box-sizing'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="box-sizing-sample">
  <h3>box-sizing: content-box;</h3>
  <div class="box-sizing box-sizing1">
    <div class="box-sizing-content highlight">
      文章
    </div>
  </div>
  <h3>box-sizing: border-box;</h3><my-code-only>
  <div class="box-sizing box-sizing2">
    <div class="box-sizing-content">
      文章
    </div>
  </div>
  </my-code-only>
<my-show-only>
  <div class="mask-wrap">
    <div class="box-sizing box-sizing2">
      <div class="box-sizing-content">
        文章
      </div>
    </div>
    <div class="mask highlight">
    </div>
  </div>
</my-show-only>
</div>
</pre>
        </template>
        <template #style>
<pre>
.box-sizing-sample {
  .box-sizing {
    background-color: lightgreen;
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border: 5px solid black;
    width: 110px;
    height: 110px;
    margin: 20px auto;
    padding: 10px;
    .box-sizing1 {
      <Marker>box-sizing: content-box;</Marker>
    }
    .box-sizing2 {
      <Marker>box-sizing: border-box;</Marker>
    }
    .box-sizing-content {
      display: flex;
      align-items: center;
      justify-content: center;
      background: yellow;
      width: 100%;
      height: 100%;
    }
  }
  /*
  .box-sizing2 {
  }
  */
  .mask-wrap {
    position: relative;
    display: inline-block;
    z-index: 100;
    margin-left: auto;
    margin-right: auto;
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 20px;
      width: 110px;
      height: 110px;
      opacity: 0.6;
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        border
      -------------------- -->
      <ExampleTemp :id="'border'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="border-sample">
  <p class="border1 highlight">
    solid
  </p>
  <p class="border2 highlight">
    double
  </p>
  <p class="border3 highlight">
    dashed
  </p>
  <p class="border4 highlight">
    dotted
  </p>
  <p class="border5 highlight">
    inset
  </p>
</div>
</pre>
        </template>
        <template #style>
<pre>
.border-sample {
  p {
    width: 240px;
    margin: 30px auto;
    padding: 10px;
  }
  .border1 {
    <Marker>border: solid medium #009b63;</Marker>
  }
  .border2 {
    <Marker>border: double 10px #009b63;</Marker>
  }
  .border3 {
    <Marker>border: dashed 10px #009b63;</Marker>
  }
  .border4 {
    <Marker>border: dotted 10px #009b63;</Marker>
  }
  .border5 {
    <Marker>border: inset  10px #009b63;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        border-bottom
      -------------------- -->
      <ExampleTemp :id="'border-bottom'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="border-bottom-sample">
  <div class="bottom1 highlight">
    下線
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.border-bottom-sample {
  .bottom1 {
    width: 240px;
    margin: 30px auto;
    padding: 10px;
    <Marker>border-bottom: solid medium #009b63;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        border-radius
      -------------------- -->
      <ExampleTemp :id="'border-radius'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="border-radius-sample">
  <div class="radius1 highlight">
    1つの値<br />border-radius: 10px;
  </div>
  <div class="radius2 highlight">
    1つの値<br />border-radius: 50%;
  </div>
  <div class="radius3 highlight">
    2つの値<br />border-radius: 10px 30px;
  </div>
  <div class="radius4 highlight">
    4つの値<br />border-radius: 0px 15px 25px 50px;
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.border-radius-sample {
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    width: 200px;
    height: 200px;
    background-color: lightblue;
  }
  .radius1 {
    <Marker>border-radius: 10px;</Marker>
  }
  .radius2 {
    <Marker>border-radius: 50%;</Marker>
  }
  .radius3 {
    <Marker>border-radius: 10px 30px;</Marker>
  }
  .radius4 {
    <Marker>border-radius: 0px 15px 25px 50px;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        overflow
      -------------------- -->
      <ExampleTemp :id="'overflow'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="overflow-sample">
  <h5>overflow: visible; を指定</h5>
  <p class="overflow1 highlight">
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>

  <h5>overflow: hidden; を指定</h5>
  <p class="overflow2 highlight">
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>

  <h5>overflow: scroll; を指定</h5>
  <p class="overflow3 highlight">
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>
  
  <h5>overflow: auto; を指定</h5>
  <p class="overflow4 highlight">
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>
  
  <h5>overflow: no-display; を指定</h5>
  <p class="overflow5 highlight">
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>
  
  <h5>overflow: no-content; を指定</h5>
  <p class="overflow6 highlight">
  1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  </p>  
</div>
</pre>
        </template>
        <template #style>
<pre>
.overflow-sample {
  p {
    background-color: #009b63;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
  .overflow1 {
    <Marker>overflow: visible;</Marker>
  }
  .overflow2 {
    <Marker>overflow: hidden;</Marker>
  }
  .overflow3 {
    <Marker>overflow: scroll;</Marker>
  }
  .overflow4 {
    <Marker>overflow: auto;</Marker>
  }
  .overflow5 {
    <Marker>overflow: no-display;</Marker>
  }
  .overflow6 {
    <Marker>overflow: no-content;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>

      <!-- --------------------
        position
      -------------------- -->
      <ExampleTemp :id="'position'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="position-sample">
  <p>
    position:static<span class="position1 highlight">staticの場合の表示</span>
  </p>
  <p>
    position:relative<span class="position2 highlight">relativeの場合の表示</span>
  </p>
  <p>
    position:absolute<span class="position3 highlight">absoluteの場合の表示</span>
  </p>
  <p class="mb-4">
    position:fixed<span class="position4 highlight">fixedの場合の表示</span><br />
    ※ 実際はブラウザのスクリーン左上に表示されます。
  </p>
  <label class="print_hidden" name="checkbox-fixed">
  ※ 実際のfixedの位置: <input type="checkbox" id="checkbox-fixed" @change="isfixedToggle" :checked="isfixed">
  </label>
</div>
</pre>
        </template>
        <template #style>
<pre>
.position-sample {
  p {
    background-color: pink;
    /* ② 子要素がposition:absolute;を指定している場合、親要素にposition: relative;を指定する必要がある。*/
    <Marker>position: relative;</Marker>
    height:50px;
    font-weight:bold;
    margin: 16px;
    span {
      color:#ff0000;
    }
    .position1 {
      <Marker>position:static;</Marker>
      top:10px;
      left:10px
    }
    .position2 {
      <Marker>position: relative;</Marker>
      top:10px;
      left:10px;
    }
    .position3 {
      /* ① 要素をabsoluteで配置する場合、親要素にposition: relative;を指定する必要がある。*/
      <Marker>position:absolute;</Marker>
      top:10px;
      left:10px;
    }
    .position4 {
      <Marker>position:fixed;</Marker>
      top: 10px;
      left: 10px;
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        top, right, bottom, left
      -------------------- -->
      <div id="top, right, bottom, left" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'top, right, bottom, left'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
        display
      -------------------- -->
      <ExampleTemp :id="'display'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="display-sample">
  <a class="display1 highlight">
    a(インライン)要素をブロック要素に
  </a>
  <div class="display2 highlight">
    div(ブロック)要素をインライン要素に
  </div>← ★<br />
  <div class="display3">
    div(ブロック)要素をフレックス要素に
    <div class="flex-container highlight">
      <div class="flex-box">フレックス1</div>
      <div class="flex-box">フレックス2</div>
      <div class="flex-box">フレックス3</div>
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.display-sample {
  a, .display1, .display2 {
    background-color:pink;
    width: 240px;
    margin: 30px auto;
  }
  .display1 {
    <Marker>display:block;</Marker>
  }
  .display2 {
    <Marker>display:inline-block;</Marker>
  }
  .display3 {
    .flex-container {
      <Marker>display: flex;</Marker> /* フレックスボックス */
      justify-content: space-between; /* 要素の間にスペースを均等に配置 */
      background-color: lightgreen; /* 背景色 */
      padding: 30px;
      background-color:pink;
    }
    .flex-box {
      width: 30%; /* 幅を30%に指定 */
      height: 100px; /* 高さを100pxに指定 */
      margin-bottom: 16px;
      background-color: lightgreen; /* 背景色 *
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        content
      -------------------- -->
      <ExampleTemp :id="'content'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="content-sample">
  <p class="content highlight">
    先頭に星を挿入しました。
  </p>
</div>
</pre>
        </template>
        <template #style>
<pre>
.content-sample {
  .content:before {
    <Marker>content: "★";</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        flex-direction
      -------------------- -->
      <ExampleTemp :id="'flex-direction'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="flex-direction-sample">
  <div class="flex-container direction-row highlight">
    <div class="flex-item ">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container direction-row-reverse highlight">
    <div class="flex-item ">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container direction-column highlight">
    <div class="flex-item ">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container direction-column-reverse highlight">
    <div class="flex-item ">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.flex-direction-sample {
  .flex-container {
    display: flex; /* フレックスボックス */
    background-color: lightgray;
    margin-bottom: 16px;
    padding: 10px;
  }
  .direction-row {
    <Marker>flex-direction: row; /* アイテムを横並び(左から右) */</Marker>
  }
  .direction-row-reverse {
    <Marker>flex-direction: row-reverse; /* アイテムを横並び(右から左) */</Marker>
  }
  .direction-column {
    <Marker>flex-direction: column; /* アイテムを縦並び(上から下) */</Marker>
  }
  .direction-column-reverse {
    <Marker>flex-direction: column-reverse; /* アイテムを縦並び(下から上) */</Marker>
  }
  .flex-item {
    background-color: lightcoral;
    margin: 5px;
    padding: 20px;
    width: 80px;
    text-align: center;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        justify-content       
      -------------------- -->
      <ExampleTemp :id="'justify-content'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="justify-content-sample">
  <div class="flex-container flex-start highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container flex-end highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container flex-center highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container flex-space-between highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container flex-space-around highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
  <div class="flex-container flex-space-evenly highlight">
    <div class="flex-item">アイテム1</div>
    <div class="flex-item">アイテム2</div>
    <div class="flex-item">アイテム3</div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.justify-content-sample {
  .flex-container {
    display: flex;
    background-color: lightgray;
    margin-bottom: 16px;
    padding: 10px;
  }
  .flex-start {
    <Marker>justify-content: flex-start; /* 左寄せ */</Marker>
  }
  .flex-end {
    <Marker>justify-content: flex-end; /* 右寄せ */</Marker>
  }
  .flex-center {
    <Marker>justify-content: center; /* 中央寄せ */</Marker>
  }
  .flex-space-between {
    <Marker>justify-content: space-between; /* 均等なスペース(最初と最後のアイテムは端にくっつきます) */</Marker>
  }
  .flex-space-around {
    <Marker>justify-content: space-around; /* 均等なスペース(最初と最後のアイテムにも少しスペースが空きます) */</Marker>
  }
  .flex-space-evenly {
    <Marker>justify-content: space-evenly; /* 均等なスペース(最初と最後のアイテムにも同じだけスペースが空きます) */</Marker>
  }
  .flex-item {
    background-color: lightcoral;
    margin: 5px;
    padding: 20px;
    width: 80px;
    text-align: center;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        align-items
      -------------------- -->
      <ExampleTemp :id="'align-items'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="align-items-sample">
  <div class="flex-container align-items-flex-start highlight">
    <div class="item">アイテム1</div>
  </div>
  <div class="flex-container align-items-center highlight">
    <div class="item">アイテム2</div>
  </div>
  <div class="flex-container align-items-flex-end highlight">
    <div class="item">アイテム3</div>
  </div>
  <div class="flex-container align-items-baseline highlight">
    <div class="item">アイテム4</div>
  </div>
  <div class="flex-container align-items-stretch highlight">
    <div class="item">アイテム5</div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.align-items-sample {
  .flex-container {
    display: flex; /* フレックスボックス */
    height: 150px; /* コンテナの高さ */
    background-color: lightgray;
    margin-bottom: 16px;
    padding: 10px;
  }
  .align-items-flex-start {
    <Marker>align-items: flex-start; /* すべてのアイテムを上寄せ */</Marker>
  }
  .align-items-center {
    <Marker>align-items: center; /* アイテムを中央寄せ */</Marker>
  }
  .align-items-flex-end {
    <Marker>align-items: flex-end; /* アイテムを下寄せ */</Marker>
  }
  .align-items-baseline {
    <Marker>align-items: baseline; /* アイテムを文字のベースラインに揃えて配置 */</Marker>
  }
  .align-items-stretch {
    <Marker>align-items: stretch; /* アイテムを高さいっぱいに揃えて配置 */</Marker>
  }
  .item {
    background-color: lightcoral;
    height: 50px;
    margin: 5px;
    padding: 10px;
    width: 80px;
    text-align: center;
    font-size: 13px;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        color
      -------------------- -->
      <ExampleTemp :id="'color'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="color-sample highlight">
  これは赤い文字です。
</div>
</pre>
        </template>
        <template #style>
<pre>
.color-sample {
  <Marker>color: red; /* 文字の色を赤にする */</Marker>
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        font-size
      -------------------- -->
      <ExampleTemp :id="'font-size'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="font-size-sample highlight">
  これは32pxの文字です。
</div>
</pre>
        </template>
        <template #style>
<pre>
.font-size-sample {
  <Marker>font-size: 32px; /* 文字の大きさを32ピクセルにする */</Marker>
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        text-decoration
      -------------------- -->
      <ExampleTemp :id="'text-decoration'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="text-decoration-sample">
  <div>
    <span class="text-decoration-overline highlight">これは上線付きの文字です。</span>
  </div>
  <div>
    <span class="text-decoration-line-through highlight">これは打消し線付きの文字です。</span>
  </div>
  <div>
    <span class="text-decoration-underline highlight">これは下線付きの文字です。</span>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.text-decoration-sample {
  div {
    margin-bottom: 16px;
  }
  .text-decoration-overline {
    <Marker>text-decoration: overline; /* 文字の上に線を引く */</Marker>
  }
  .text-decoration-line-through {
    <Marker>text-decoration: line-through; /* 文字の中央に線を引く */</Marker>
  }
  .text-decoration-underline {
    <Marker>text-decoration: underline; /* 文字の下に下線を引く */</Marker>
  }
}

</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        margin
      -------------------- -->
      <ExampleTemp :id="'margin'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="margin-sample">
  <h4>※ 白い部分がマージンです。</h4>
  <div>margin: 16px;</div>
  <div class="margin-container highlight">
    <div class="margin1">
      
    </div>
  </div>
  <div>margin: 16px 8px;</div>
  <div class="margin-container highlight">
    <div class="margin2">
      
    </div>
  </div>
  <div>margin: 16px 8px 30px;</div>
  <div class="margin-container highlight">
    <div class="margin3">
      
    </div>
  </div>
  <div>margin: 16px 8px 30px 50px;</div>
  <div class="margin-container highlight">
    <div class="margin4">
      
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.margin-sample {
  .margin-container {
    background: white;
    display: flex; 
    align-items: center;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    padding: 0;
    > div {
      background-color: lightgreen;
      display: flex; 
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 1px solid black;
    }
    .margin1 {
      width: 118px;
      height: 118px;
      <Marker>margin: 16px;</Marker>
    }
    .margin2 {
      width: 134px;
      height: 118px;
      <Marker>margin: 16px 8px;</Marker>
    }
    .margin3 {
      width: 134px;
      height: 106px;
      <Marker>margin: 16px 8px 30px;</Marker>
    }
    .margin4 {
      width: 92px;
      height: 106px;
      <Marker>margin: 16px 8px 30px 50px;</Marker>
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        margin-top
      -------------------- -->
      <ExampleTemp :id="'margin-top'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="margin-top-sample">
  <h4>※ 白い部分がマージンです。</h4>
  <div>margin-top: 16px;</div>
  <div class="margin-top-container highlight">
    <div class="margin1">
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.margin-top-sample {
  .margin-top-container {
    background: white;
    display: flex; 
    align-items: center;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    padding: 0;
    > div {
      background-color: lightgreen;
      display: flex; 
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 1px solid black;
    }
    .margin1 {
      width: 150px;
      height: 134px;
      <Marker>margin-top: 16px;</Marker>
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        padding
      -------------------- -->
      <ExampleTemp :id="'padding'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="padding-sample">
  <h4>※ 四角い枠線がボックス要素(divタグ, pタグ等)</h4>
  <h4>※ 白い部分がマージン</h4>
  <h4>※ 緑の部分がパッディング</h4>
  <h4>※ 黄色い部分がコンテンツ</h4>
  <div>margin: 20px;</div>
  <div>padding: 8px;</div>
  <div class="padding-container">
    <div class="padding1 highlight">
      <div class="padding1-content">
        文章
      </div>
    </div>
  </div>
  <div>margin: 20px;</div>
  <div>padding: 20px 8px;</div>
  <div class="padding-container">
    <div class="padding2 highlight">
      <div class="padding2-content">
        文章
      </div>
    </div>
  </div>
  <div>margin: 20px;</div>
  <div>padding: 16px 8px 30px;</div>
  <div class="padding-container">
    <div class="padding3 highlight">
       <div class="padding3-content">
        文章
      </div>
    </div>
  </div>
  <div>margin: 20px;</div>
  <div>padding: 16px 8px 30px 50px;</div>
  <div class="padding-container">
    <div class="padding4 highlight">
      <div class="padding4-content">
        文章
      </div>     
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.padding-sample {
  .padding-container {
    background: white;
    display: flex; 
    align-items: center;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    padding: 0;
    .padding1, .padding2, .padding3, .padding4  {
      background-color: lightgreen;
      display: flex; 
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border: 2px solid black;
      width: 110px;
      height: 110px;
      margin: 20px;
      > div {
        border: 1px solid black;
        display: flex; 
        align-items: center;
        justify-content: center;
      }
    }
    .padding1 {
      <Marker>padding: 8px;</Marker>
      .padding1-content {
        background: yellow;
        width: 100%;
        height: 100%;
      }
    }
    .padding2 {
      <Marker>padding: 20px 8px;</Marker>
      .padding2-content {
        background: yellow;
        width: 94px;
        height: 70px;
      }
    }
    .padding3 {
      <Marker>padding: 16px 8px 30px;</Marker>
      .padding3-content {
        background: yellow;
        width: 94px;
        height: 64px;
      }
    }
    .padding4 {
      <Marker>padding: 16px 8px 30px 50px;</Marker>
      .padding4-content {
        background: yellow;
        width: 52px;
        height: 64px;
      }
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        cursor
      -------------------- -->
      <ExampleTemp :id="'cursor'" :list="list" :pdfNextPageShow="true" :isnobrowsershowcheckbox="true">
        <template #template>
<pre>
<div class="cursor-sample">
  <div class="cursor1">
    ここをクリックしてみてください。<br />
    矢印カーソル(デフォルト)のポインタが表示されます。<br />
    cursor: default;
  </div>
  <div class="cursor2">
    ここをクリックしてみてください。<br />
    手のひらカーソルのポインタが表示されます。<br />
    cursor: pointer;
  </div>
  <div class="cursor3">
    ここをクリックしてみてください。<br />
    四方矢印カーソルのポインタが表示されます。<br />
    cursor: move;
  </div>
  <div class="cursor4">
    ここをクリックしてみてください。<br />
    文字の選択カーソルのポインタが表示されます。<br />
    cursor: text;
  </div>
  <div class="cursor5">
    ここをクリックしてみてください。<br />
    クロスヘア（十字の線）カーソルのポインタが表示されます。<br />
    cursor: crosshair;
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.cursor-sample {
  div {
    margin-bottom: 16px;
    border: 1px solid black;
    display: inline-block;
    padding: 16px;
    border-radius: 5px;
  }
  .cursor1 {
    <Marker>cursor: default;</Marker>
  }
  .cursor2 {
    <Marker>cursor: pointer;</Marker>
  }
  .cursor3 {
    <Marker>cursor: move;</Marker>
  }
  .cursor4 {
    <Marker>cursor: text;</Marker>
  }
  .cursor5 {
    <Marker>cursor: crosshair;</Marker>
  }
}
</pre>
        </template>
      </ExampleTemp>

      <PdfNextPage />
      <!-- --------------------
        ノート
      -------------------- -->
      <div id="note" class="note">
        <h4 class="text-center">ノート</h4>
        <ul class="h2-list">
          <li id="inlineblock">
            インラインとブロックとは
            <div>
              ブロック要素とは、見出し、段落、リスト、フォームなどのひとつのまとまった単位として表される要素で、一般的なブラウザでは前後に改行が入って表示されます。 インライン要素とは、主に文章の一部として利用される要素でその前後は改行されません。
            </div>
          </li>
          <li id="gijiyouso">
            疑似要素とは
            <div>
              擬似要素（pseudo-element）とは、直訳すれば｢偽りの要素｣となります。 要素の一部にスタイルを適用するために、擬似的に設定される｢要素のようなもの｣のことです。 :before擬似要素および:after擬似要素は、要素の直前および直後に、文字列や画像などのコンテンツ（内容）を挿入するために擬似的に設定されます。            
            </div>
          </li>
          <li> ボタン クラス
          <div>
            <button class="btn btn-white my-4">ボタン</button>
          </div>
          <div>
            &lt;template&gt;<br />
            &nbsp;&nbsp;{{`<button class="btn btn-white">ボタン</button>`}}<br />
            &lt;/template&gt;<br />
            &lt;style&gt;<br />
              {{
`.btn {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: 600;
  .btn-white {
    background: rgb(255 255 255 / 1);
    border: 1px solid rgb(229 231 235 / 1);
    color: rgb(31 41 55 / 1);
  }
}`}}<br />
            &lt;/style&gt;<br />
          </div>
          </li>
        </ul>
      </div>

    </div>


</div>
</template>

<script setup>
import { ref } from "vue";
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import PdfNextPage from "@/components/PdfNextPage.vue";
import Marker from "@/components/MarkerComponent.vue";

const isfixed = ref(false);

const isfixedToggle = () => {
  const el = document.getElementsByClassName("position4")[0];
  if (isfixed.value) {
    el.classList.remove("!fixed")
  } else {
    el.classList.add("!fixed")
//
  }
  isfixed.value = !isfixed.value;
}

const list = [
  {
    title: "文字色・背景",
    list: [
      {
        id: "background",
        title: "背景(一括設定)",
        description: "backgroundプロパティは、背景に関する情報をまとめて行う。backgroundプロパティは、背景に関する指定をまとめて行う際に使用します。 指定の順序は問われません。また、必要のない指定は省略可能です。",
      }, {
        id: "background-color",
        title: "背景色",
        description: "background-colorプロパティは、背景色を指定します。",
      },
    ],
  }, {
    title: "フォント",
    list: [
      {
        id: "font-weight",
        title: "フォントの太さ",
        description: `<p>font-weightプロパティは、フォントの太さを指定する際に使用します。 9段階の太さを指定することができますが、一般的なフォントでは9種類の太さが用意されていることはあまりなく、 フォントの太さとして実際に指定する値は｢bold｣のみで事足りることが多いようです。</p>
          <dl class="description-option">
            <dt>数値で指定</dt>
            <dd>
              100、200、300、400、500、600、700、800、900でフォントの太さを指定します。太さが9種類用意されているフォントはあまりないため、数値を上下させても太さが変化しないことがあります。標準の太さは400で、数値が小さくなるほど細く、大きくなるほど太くなります。
            </dd>
            <dt>キーワードで指定</dt>
            <dd>
            normal …… 標準の太さです。（数値で400を指定した場合と同じ<br />
            bold …… 一般的な太字の太さです。（数値で700を指定した場合と同じ）<br />
            lighter …… 相対的に一段階細くします。<br />
            bolder …… 相対的に一段階太くします。<br />
            </dd>
          </dl>`
      }
    ],
  }, {
    title: "テキスト",
    list: [
      {
        id: "text-align",
        title: "(ボックス内の要素に対して) 左・中央・右寄せなど",
        description: `<p>text-alignプロパティは、ブロックコンテナ内の行の揃え位置・均等割付を指定する。ラインボックスをコンテンツ（テキスト内容）が完全に満たしていない場合に、そのコンテンツがインライン軸に沿ってどのように整列するかを設定します。</p>
          <dl class="description-option">
            <dt>
              start
            </dt>
            <dd>
              インラインレベルのコンテンツは、ラインボックスの始端に揃えられる（初期値）。
            </dd>
            <dt>
              end 
            </dt>
            <dd>
              インラインレベルのコンテンツは、ラインボックスの始端に揃えられる（初期値）。
            </dd>
            <dt>
              left 
            </dt>
            <dd>
              インラインレベルのコンテンツは、ラインボックスの左端に揃えられる（縦書きモードではtext-orientationプロパティで指定するテキストの方向次第で上端または下端のいずれかに揃えられる）。
            </dd>
            <dt>
              center
            </dt>
            <dd>
              インラインレベルのコンテンツは、ラインボックスの中央に配置される。
            </dd>
            <dt>
              right
            </dt>
            <dd>
              インラインレベルのコンテンツは、ラインボックスの右端に揃えられる（縦書きモードではtext-orientationプロパティで指定するテキストの方向次第で上端または下端のいずれかに揃えられる）。
            </dd>
            <dt>
              justify
            </dt>
            <dd>
              均等割付。最後の行についてはtext-align-lastプロパティの指定がない限り、ラインボックスの始端に揃えられる。
            </dd>
            <dt>
              justify-all
            </dt>
            <dd>
              均等割付。最後の行も強制的に均等割付となる。
            </dd>
            <dt>
              match-parent
            </dt>
            <dd>
              親要素の値を継承する。継承した値が start または end の場合は left または right と解釈される。
            </dd>
          </dl>`,
      }, {
        id: "vertical-align",
        title: "縦方向の配置を調整",
        description: `<p>CSSのvertical-alignプロパティは、縦方向の配置を調整するためのプロパティです。このプロパティを使うと、テキストや画像などの要素を縦にどの位置に置くかを指定することができます。例えば、文字の中に画像を入れたときに、画像を文字の真ん中に合わせたり、下に合わせたりすることができます。</p>
          <dl class="description-option">
            <dt>auto</dt>
            <dd>
              自動算出します。通常は baseline になります。
            </dd>
            <dt>top</dt>
            <dd>
              その行で一番高いテキストの上端に、要素の上端を揃えます。
            </dd>
            <dt>bottom</dt>
            <dd>
              その行で一番低いテキストの下端に、要素の下端を揃えます。
            </dd>
            <dt>text-top</dt>
            <dd>
              基準テキストの上端に、要素の上端を揃えます。
            </dd>
	          <dt>text-bottom</dt>
            <dd>
              基準テキストの下端(文字yの下端)に、要素の下端を揃えます。
            </dd>
	          <dt>middle</dt>
            <dd>
              基準テキストの文字xの高さの中央に、要素の中央を揃えます。
            </dd>
	          <dt>baseline</dt>
            <dd>
              基準テキストのベースライン(文字Mの下端)に、対象要素のベースラインを揃えます。(C1/e/Fx/Ch/Op/Sa)
            </dd>
	          <dt>super</dt>
            <dd>
              標準テキストの上付文字のベースラインに要素のベースラインを揃えます。
            </dd>
	          <dt>sub</dt>
            <dd>
              標準テキストの下付文字のベースラインに要素のベースラインを揃えます。
            </dd>
          </dl>`
      }, {
        id: "line-height",
        title: "行間を調整",
        description: "CSSのline-heightプロパティは、行間を調整するためのプロパティです。行間とは、テキストが上下にどれだけスペースを持つかを決めるものです。このプロパティを使うと、文章が読みやすくなるように、行と行の間隔を広げたり、狭めたりすることができます。例えば、文章がぎゅっと詰まっていると読みづらいですよね。line-heightを使うと、行と行の間に余白を作って、もっと見やすくすることができます。",
      },
    ],
  }, {
    title: "サイズ",
    list: [
      {
        id: "width",
        title: "要素の幅",
        description: "widthプロパティは、要素の幅を指定する。指定方法には、実数値にpxなどの単位をつけて指定する方法と、親ボックスに対する割合を％で指定する方法と、 状況に応じて大きさが設定されるautoを指定する方法があります。widthプロパティに負の値は指定できません。",
      }, {
        id: "height",
        title: "要素の高さ",
        description: `CSSのheightプロパティは、height（ハイト）とは、「高さ」を指定するためのCSSのプロパティです。このプロパティを使うと、ウェブページの要素（例えば、画像やボックスなど）の高さを決めることができます。たとえば、ボックスの高さを「100ピクセル」にしたり、画像の高さを「200ピクセル」にしたりすることができます。これを使うことで、デザインを整えたり、要素の大きさをコントロールすることができます。heightを使うときは、HTMLで作った要素にCSSを追加して、その高さを指定します。たとえば、ボックスの高さを設定する場合は、height: 100px;のように書きます。`
      }, {
        id: "box-sizing",
        title: "ボックスの大きさに枠線や内側の余白が含まれるかどうか",
        description: `<p>CSSのbox-sizingプロパティは、ボックス（箱）の大きさをどのように計算するかを決めることができます。box-sizingを使うことで、ボックスの大きさに枠線（border）や内側の余白（padding）が含まれるかどうかを決めることができるんです。例えば、あなたが箱を作ったときに、その箱の中身（コンテンツ）や、箱の周りの枠線や内側の余白も含めて、箱の大きさを決めたい場合があるかもしれません。box-sizingを使うことで、その計算の仕方を変えることができるんです。</p>
          <dl class="description-option">
            <dt>content-box（デフォルト設定）</dt>
            <dd>
              これが普通の設定で、箱の幅（width）は中身の大きさだけで、枠線や内側の余白はその外に追加されます。
            </dd>
            <dt>border-box</dt>
            <dd>
              こちらは、箱の幅（width）の中に枠線や内側の余白も含めて計算します。指定した幅にぴったり収まるように調整されます。
            </dd>
          </dl>`,
      }
    ],
  }, {
    title: "ボーダー",
    list: [
      {
        id: "border",
        title: "ボーダー(一括設定: 上下左右)",
        description: "borderプロパティは、ボーダーのスタイル・太さ・色をまとめて指定する際に使用します。 スタイル・太さ・色のうち、指定する値を任意の順序でスペースで区切って記述します。borderプロパティでは、ボーダーのスタイル・太さ・色について上下左右バラバラの指定をすることはできません。 上下左右のボーダーを異なったものにする場合には、 border-top、 border-bottom、 border-left、 border-right、 または、 border-style、 border-width、 border-color、 で指定してください。",
      }, {
        id: "border-bottom",
        title: "ボーダー下部",
        description: "CSSのborder-bottomプロパティは、ページの要素（例えば、テキストや画像）の下に線を引くための指定方法です。border-bottomを使うと、ページの中の何かの下に「下線」を付けることができます。",
      }
    ],
  }, {
    title: "ボーダー半径",
    list: [
      {
        id: "border-radius",
        title: "四角の角を丸くする",
        description: `<p>CSSのborder-radiusプロパティは、角を丸くするためのCSSプロパティです。このプロパティを使うと、四角い形を持つボックス（例えば、ボタンや画像の枠）や要素の角を丸くすることができます。例えば、普通の四角形のボックスやボタンが角を丸くして、少し柔らかい印象を与えることができます。</p>
          <dl class="description-option">
            <dt>
              1つの値
            </dt>
            <dd>
              すべての角を同じ大きさで丸くします。
            </dd>
            <dt>
              2つの値
            </dt>
            <dd>
              上下の角と左右の角を別々に丸くできます。
            </dd>
            <dt>
              4つの値
            </dt>
            <dd>
              それぞれの角を個別に設定することができます。
            </dd>
          </dl>`
      }
    ],
  }, {
    title: "表示・配置",
    list: [
      {
        id: "overflow",
        title: "はみ出た要素の表示・非表示",
        description: `<p>overflowプロパティは、ボックスの範囲内に内容が収まらない場合に、はみ出た部分の表示の仕方を指定する際に使用します。左右・上下のオーバーフローについてまとめて指定することのできるショートハンドプロパティ（短縮形）です。 キーワードを半角スペースで区切って記述することで、 overflow-xプロパティ と overflow-yプロパティ の値をまとめて指定できます。</p>
          <dl class="description-option">
            <dt>
              visible
            </dt>
            <dd>
              内容がボックスに収まらない場合、ボックスからはみ出して表示される（初期値）。
            </dd>
            <dt>
              hidden
            </dt>
            <dd>
              内容がボックスに収まらない場合、収まらない部分は非表示となる。内容が収まらない場合にも、スクロールバーなどは表示されない。
            </dd>
            <dt>
              scroll
            </dt>
            <dd>
              内容がボックスに収まらない場合、収まらない部分はボックスからはみ出さない。内容が収まらない場合には、スクロールバーなどが表示される。
            </dd>
            <dt>
              auto
            </dt>
            <dd>
              ボックスに収まらない内容をどう表示するかは、ユーザーエージェントに依存する。内容が収まらない場合には、スクロールバーなどが表示される。
            </dd>
            <dt>
              no-display
            </dt>
            <dd>
              内容がボックスに収まらない場合、display: none; が指定されたかのようにボックス全体が削除される。
            </dd>
            <dt>
              no-content
            </dt>
            <dd>
              内容がボックスに収まらない場合、visibility: hidden; が指定されたかのようにボックス全体が非表示となる。
            </dd>
          </dl>`,
      }, {
        id: "position",
        title: "ボックスの配置方法（基準位置）",
        description: `<p>positionプロパティは、ボックスの配置方法（基準位置）が、相対位置か絶対位置かを指定する際に使用します。positionプロパティで指定するのは、配置方法（基準位置）のみです。 実際の表示位置の指定には、 top、 bottom、 left、 rightを併用して、基準位置からの距離を設定する必要があります。</p>
          <dl class="description-option">
            <dt>static</dt>
            <dd>
              特に配置方法を指定しません。この値のときには、top、bottom、left、rightは適用されません。これが初期値です。
            </dd>
            <dt>relative</dt>
            <dd>
              相対位置への配置となります。positionプロパティでstaticを指定した場合に表示される位置が基準位置となります。
            </dd>
            <dt>absolute</dt>
            <dd>
              絶対位置への配置となります。親ボックスにpositionプロパティのstatic以外の値が指定されている場合には、親ボックスの左上が基準位置となります。親ボックスにpositionプロパティのstatic以外の値が指定されていない場合には、ウィンドウ全体の左上が基準位置となります。
            </dd>
            <dt>fixed</dt>
            <dd>
              絶対位置への配置となるのはabsoluteと同じですが、スクロールしても位置が固定されたままとなります。
            </dd>
          </dl>`
      }, {
        id: "top, right, bottom, left",
        title: "positionと一緒に上、右、下、左からの配置位置（距離）を指定",
        description: `<a class="a" href="#position">※positionで説明</a>`,
      }
    ],
  }, {
    title: "表示",
    list: [
      {
        id: "display",
        title: "要素の表示形式（ブロック・インライン・フレックス等）を指定",
        description: `CSSのdisplayプロパティは、「要素の表示方法」を決めるためのものです。つまり、HTMLの要素（例えば、文字や画像、ボックスなど）がどのように並んだり、表示されたりするかを指定することができます。例えば、文字が横並びで表示されるのか、縦に並ぶのか、ボックスが新しい行に表示されるのか、などを決めるのがdisplayプロパティです。
        <a class="a" href="#inlineblock">※インラインとブロックとは</a>`
      }
    ],
  }, {
    title: "内容挿入・引用符",
    list: [
      {
        id: "content",
        title: "CSSからコンテンツを追加",
        description: `<p>
          contentプロパティは、要素の直前または直後に、文字列や画像などのコンテンツを挿入する際に使用します。 contentプロパティを適用することができるのは、:before擬似要素および:after擬似要素のみです。<a class="a" href="#gijiyouso">※擬似要素とは</a>
          </p>`,
      }
    ],
  }, {
    title: "フレックスボックス",
    list: [
      {
        id: "flex-direction",
        title: "アイテム（要素）の並べ方の向き",
        description: `<p>CSSのflex-directionプロパティとは、フレックスボックス（display: flex）を使うときに、アイテム（要素）の並べ方の向きを指定するためのプロパティです。これを使うことで、フレックスボックス内のアイテムが横並びにするか、縦並びにするかを決めることができます。</p>
          <dl class="description-option">
            <dt>row（デフォルト）</dt>
            <dd>
              アイテムを横並びに並べます。
            </dd>
            <dt>row-reverse</dt>
            <dd>
              アイテムを横並びに並べますが、順番が逆になります（右から左）。
            </dd>
            <dt>column</dt>
            <dd>
              アイテムを縦並びに並べます。
            </dd>
            <dt>column-reverse</dt>
            <dd>
              アイテムを縦並びに並べますが、順番が逆になります（下から上）。
            </dd>
          </dl>`,
      }
    ],
  }, {
    title: "ボックス配置",
    list: [
      {
        id: "justify-content",
        title: "アイテム（要素）を左右や上下に並べる位置を調整",
        description: `<p>CSSのjustify-contentプロパティとは、フレックスボックス（display: flex）やグリッドボックス（display: grid）のコンテナ内で、アイテム（要素）を左右や上下に並べる位置を調整するためのプロパティです。
特にjustify-contentを使うとアイテムが横並びまたは縦並びになるときに、アイテム同士の間隔や配置を調整することができます。justify-contentは、主に横並びのアイテム（display: flexを使っている場合）で使います。このプロパティを使うことで、アイテムを「中央に寄せる」「左右に均等に並べる」「アイテム間にスペースを空ける」などの配置をすることができます。</p>
           <dl class="description-option">
            <dt>flex-start</dt>
            <dd>
              アイテムを左寄せにします（デフォルトの設定）。
            </dd>
            <dt>flex-end</dt>
            <dd>
              アイテムを右寄せにします。
            </dd>
            <dt>center</dt>
            <dd>
              アイテムを中央寄せにします。
            </dd>
            <dt>space-between</dt>
              アイテムの間に均等なスペースを空けます（最初と最後のアイテムは端にくっつきます）。
            <dd>
            </dd>
            <dt>space-around</dt>
            <dd>
              アイテムの間に均等なスペースを空け、最初と最後のアイテムにも少しスペースが空きます。
            </dd>
            <dt>space-evenly</dt>
            <dd>
              アイテムの間に均等なスペースを空け、最初と最後のアイテムにも同じだけスペースが空きます。
            </dd>
          </dl>`,
      }, {
        id: "align-items",
        title: "アイテム（要素）の縦方向（交差軸）での位置を調整",
        description: `<p>
          CSSのalign-itemsプロパティは、フレックスボックス（display: flex）を使うときに、アイテム（要素）の縦方向（交差軸）での位置を調整するためのプロパティです。これを使うと、フレックスボックス内のアイテムが縦にどのように並ぶかを決めることができます。具体的には、アイテムを上下の位置で上寄せ、中央寄せ、下寄せするなど、縦方向での配置を調整することができます。</p>
          <dl class="description-option">
            <dt>flex-start</dt>
            <dd>
              アイテムを上寄せに配置します。
            </dd>
            <dt>center</dt>
            <dd>
              アイテムを縦の中央に配置します。
            </dd>
            <dt>flex-end</dt>
            <dd>
              アイテムを下寄せに配置します。
            </dd>
            <dt>baseline</dt>
            <dd>
              アイテムを基準線（文字のベースライン）に揃えます。
            </dd>
            <dt>stretch（デフォルト）</dt>
            <dd>
              アイテムを高さいっぱいに引き伸ばして配置します。
            </dd>
          </dl>`
      }
    ],
  }, {
    title: "色",
    list: [
      {
        id: "color",
        title: "文字の色",
        description: "CSSのcolorプロパティは、文字（テキスト）の色を指定するためのプロパティです。このプロパティを使うと、ウェブページに表示されている文字の色を自由に変えることができます。",
      }
    ],
  }, {
    title: "フォント",
    list: [
      {
        id: "font-size",
        title: "文字の大きさ",
        description: "CSSのfont-sizeプロパティは、文字の大きさを決めるためのCSSプロパティです。このプロパティを使うと、ウェブページに表示される文字の大きさを変更できます。",
      }
    ],
  }, {
    title: "テキストデコレーション",
    list: [
      {
        id: "text-decoration",
        title: "テキストの装飾(下線や文字の打ち消し線)",
        description: `<p>CSSのtext-decorationプロパティは、テキスト（文字）の装飾を変更するためのプロパティです。これを使うと、文字に線を引いたり、下線を引いたり、文字の装飾を取り消したりすることができます。例えば、リンクに下線を引くときや、文字を打ち消し線で表示したいときに使います。</p>
          <dl class="description-option">
            <dt>underline</dt>
            <dd>
              文字の下に下線を引く
            </dd>
            <dt>overline</dt>
            <dd>
              文字の上に上線を引く
            </dd>
            <dt>line-through</dt>
            <dd>
              文字に打ち消し線を引く
            </dd>
            <dt>none</dt>
            <dd>
              文字の装飾をなしにする（線を引かない）
            </dd>
          </dl>`
      }
    ],
  }, {
    title: "マージン - 要素の外側の余白（隙間）",
    list: [
      {
        id: "margin",
        title: "要素の外側の余白（隙間）を一括指定",
        description: `<p>CSSのmarginプロパティは、外側の余白を調整するためのプロパティです。要素（例えば、ボックスや画像など）とその周りの要素との間に「隙間」を作ることができます。この「隙間」の部分をマージン（余白）と言います。例えば、あるボックスをページの中で少し離して表示したい時に、このmarginを使います。</p>
          <dl class="description-option">
            <dt>1つの値(margin: A;)</dt>
            <dd>
              すべての方向（上、右、下、左）に同じ余白を指定
            </dd>
            <dt>2つの値(margin: A B;)</dt>
            <dd>
              上と下に1つ、右と左に1つの余白を指定
            </dd>
            <dt>3つの値(margin: A B C;)</dt>
            <dd>
              上、右・左、下の順番で余白を指定
            </dd>
            <dt>4つの値(margin: A B C D;)</dt>
            <dd>
              上、右、下、左の順番で余白を指定
            </dd>
          </dl>`
      }, {
        id: "margin-top",
        title: "要素の上部外側の余白（隙間）",
        description: "CSSのmargin-topプロパティは、上側の余白を調整するためのプロパティです。要素の上にどれくらいの隙間を作るかを指定できます。この隙間を作ることで、要素の上部に空間を空けることができます。たとえば、ボックスの上に少しスペースを空けたいときにmargin-topを使います。",
      }
    ]
  }, {
    title: "パディング - 要素の内側の余白（隙間）",
    list: [
      {
        id: "padding",
        title: "要素の内側の余白（隙間）を一括指定",
        description: `<p>CSSのpaddingプロパティは、内側の余白を調整するためのプロパティです。要素（ボックスやテキストなど）の内部とその内容（テキストや画像など）との間に隙間を作ります。たとえば、ボックスの中に文字があるとき、文字がボックスの端にぴったりくっついていると見にくいので、文字とボックスの端の間に少しスペースを空けて、見た目を良くすることができます。このスペースを作るのがpaddingです。<p>
          <dl class="description-option">
            <dt>1つの値(margin: A;)</dt>
            <dd>
              すべての方向（上、右、下、左）に同じ余白を指定
            </dd>
            <dt>2つの値(margin: A B;)</dt>
            <dd>
              上と下に1つ、右と左に1つの余白を指定
            </dd>
            <dt>3つの値(margin: A B C;)</dt>
            <dd>
              上、右・左、下の順番で余白を指定
            </dd>
            <dt>4つの値(margin: A B C D;)</dt>
            <dd>
              上、右、下、左の順番で余白を指定
            </dd>
          </dl>`
      }
    ]
  }, {
    title: "ユーザインタフェース",
    list: [
      {
        id: "cursor",
        title: "マウスカーソルの形",
        description: `<p>CSSのcursorプロパティは、マウスカーソル（マウスのポインター）の形を変更するためのCSSプロパティです。ウェブページでリンクやボタンなどをクリックできる場所にカーソルを合わせると、カーソルが変わることがありますよね。このcursorプロパティを使うと、カーソルを好きな形に変更することができます。例えば、カーソルが矢印のままでなく、指の形（リンクを示す形）にしたり、カーソルが動かせる場所では「手のひら」の形にしたりすることができます。</p>
          <dl class="description-option">
            <dt>default</dt>
            <dd>
              通常の矢印カーソル（デフォルトの形）に戻ります。
            </dd>
            <dt>pointer</dt>
            <dd>
              クリック可能なリンクやボタンの上にカーソルを置いたときに、手のひらの形に変わります。
            </dd>
            <dt>move</dt>
            <dd>
              移動可能な場所にカーソルを置いたときに、四方矢印の形に変わります。
            </dd>
            <dt>text</dt>
            <dd>
              テキストを選択できる場所にカーソルを置いたときに、文字の選択カーソルに変わります。
            </dd>
            <dt>crosshair</dt>
            <dd>
              クロスヘアの形（十字の線）になります。
            </dd>
          </dl>`
      }
    ]
  },
]
</script>

<style scope>
/*
  position
*/
.position4 {
  position: absolute!important;
  top: 10px;
  left: 10px;
}

.content, .color-sample, .font-size-sample {
  display: inline-block;
}
</style>
