<template>
<div class="main01 reference vue">

    <h1 class="midasi text-xl">Vue.js 3 リファレンス</h1>
    <Reference :list="list" />
    <div class="examples">
      <!-- --------------------
        defineProps
      -------------------- -->
      <ExampleTemp :id="'defineProps'" :list="list" :pdfNextPageShow="true" :isnoshow="true">
        <template #codeTitle>
          @/components/sample/ChildComponentOfDefineProps.vue
        </template>
        <template #template>
<pre>
<Child presentToChild="520円"></Child>
</pre>
        </template>
        <template #script>
<pre>
{{commonDefineProps}}
</pre>
        </template>
      </ExampleTemp>
      <ExampleTemp>
        <template #template>
<pre>
<my-code-only v-pre>&lt;Child presentToChild="520円"&gt;&lt;/Child&gt;</my-code-only>
<my-show-only><Child presentToChild="520円"></Child></my-show-only>
</pre>
        </template>
        <template #script>
<pre>
import Child from "@/components/sample/ChildComponentOfDefineProps.vue";
</pre>

        </template>
      </ExampleTemp>
      <!-- --------------------
        ref
      -------------------- -->
      <ExampleTemp :id="'ref'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="ref-sample">
  <div>
    <my-code-only><input v-pre type="text" v-model="refObj" /></my-code-only>
<my-show-only><input type="text" v-model="refObj" /></my-show-only>

  </div>
  <div>
    ただいまRef変数の値は「 <span class="highlight"><my-code-only v-pre>{{""===refObj ? "空" : refObj}}</my-code-only>
<my-show-only>
      {{""===refObj ? "空" : refObj}}
    </my-show-only>
</span> 」です。
  </div>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{refCode}}
</pre>
        </template>
        <template #style>
<pre>
.ref-sample {
  div:first-child {
    margin-bottom: 0.5rem; /* 8px */
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        useRouter       
      -------------------- -->
      <ExampleTemp :id="'useRouter'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="use-router-sample">
  <div><my-code-only>
    &lt;button class="btn btn-white highlight" @click="gotoHomeOfUseRouter()"&gt;スクリプトからホームページに遷移&lt;/button&gt;
</my-code-only>
<my-show-only>
  <button class="btn btn-white highlight" @click="gotoHomeOfUseRouter()">スクリプトからホームページに遷移</button>
</my-show-only>
  </div>
  <div>
    <router-link class="a highlight" to="/">テンプレートからホームページに遷移</router-link>
  </div>
  <div class="buttons"><my-code-only>
    &lt;button class="btn btn-white highlight" @click="gotoGirlFriendFromParamsOfUseRouter()"&gt;（パラメータ経由で）花子にプレゼントを渡す&lt;/button&gt;
    &lt;button class="btn btn-white highlight" @click="gotoGirlFriendFromQueryOfUseRouter()"&gt;（クエリ経由で）花子にプレゼントを渡す&lt;/button&gt;</my-code-only>
<my-show-only>
    <button class="btn btn-white highlight" @click="gotoGirlFriendFromParamsOfUseRouter()">（パラメータ経由で）花子にプレゼントを渡す</button>
    <button class="btn btn-white highlight" @click="gotoGirlFriendFromQueryOfUseRouter()">（クエリ経由で）花子にプレゼントを渡す</button>
</my-show-only>

  </div>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{commonUseRouter}}

{{codeGotoHomeOfUseRouter}}

{{codeGotoGirlFromParamsOfUseRouter}}

{{codeGotoGirlFromQueryOfUseRouter}}
</pre>
        </template>
        <template #style>
<pre>
.use-router-sample {
  margin-bottom: 1.5rem !important; /* 24px */
}
.buttons {
  button:first-child {
    margin-bottom: 0.5rem !important; /* 8px */
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        useRoute
      -------------------- -->
      <ExampleTemp :id="'useRoute'" :list="list" :pdfNextPageShow="true" :isnoshow="true" :isjscode="true">
        <template #codeTitle>
          @/router/index.js
        </template>
        <template #script>
<pre>
import { createRouter, createWebHistory } from 'vue-router';
import UseRouteSampleGirlfriendView from '@/views/UseRouteSampleGirlfriendView.vue';

const routes = [
  {
    ...
  }, {
    <Marker>path: '/girlfriend/:present*',</Marker>
    <Marker>name: 'Girlfriend',</Marker>
    component: UseRouteSampleGirlfriendView
  }, {
    ...
  }
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
export default router;
</pre>
        </template>
      </ExampleTemp>
      <ExampleTemp>
        <template #codeTitle>
          @/views/UseRouteSampleGirlfriendView.vue
        </template>
        <template #template>
<pre>
<my-code-only v-pre><div v-if="presentFromParams">
  <div>(パラメータ経由で) 太郎からもらったプレゼント：</div>
  <div>{{presentFromParams}}</div>
</div>
<div v-if="presentFromQuery">
  <div>(クエリ経由で) 太郎からもらったプレゼント：</div>
  <div>{{presentFromQuery}}</div>
</div></my-code-only>
  <my-show-only><div>
    <div>(パラメータ経由で) 太郎からもらったプレゼント：</div>
    <div><span class="highlight">ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券</span></div>
  </div>
  <div>
    または
  </div>
  <div>
    <div>(クエリ経由で) 太郎からもらったプレゼント：</div>
    <div><span class="highlight">ディズニーシー ホテル(ミラコスタ) 無料宿泊券</span></div>
  </div>
</my-show-only>
</pre>
        </template>
        <template #script>
<pre>
import { ref } from 'vue';
<Marker>// ① useRouteメソッドをインポート
import { useRoute } from 'vue-router';
// ② Routeオブジェクトを作成
const route = useRoute();</Marker>

<Marker>// ③ パラメータを取得
const presentFromParams = ref(route.params.present);</Marker>
<Marker>// ③ クエリを取得
const presentFromQuery = ref(route.query.present);</Marker>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        useStore
      -------------------- -->
      <ExampleTemp :id="'useStore'" :list="list" :pdfNextPageShow="true" :isnoshow="true" :isjscode="true">
        <template #codeTitle>
          @/store/index.js
        </template>
        <template #script>
<pre>
<Marker>const store = new vuex.Store({
  state: {
    count: 0
  },
  // ゲッター：ステート内容を加工＆取得
  getters: {
    // ソースコードの③からアクセスされる
    getCount(state) {
      return state.count;
    }
  },
  // ミューテーション：ストアの状態を操作
  mutations: {
    // ソースコードの④からアクセスされる
    increment(state) {
      state.count++;
    }
  },
  // アクション：非同期処理を実装
  actions: {
  },
  // モジュール：巨大なストアを分割管理
  modules: {
  }
});

export default store;</Marker>
</pre>
        </template>
      </ExampleTemp>
      <ExampleTemp>
        <template #template>
<pre>
<my-code-only><div>
  カウンター：<span v-pre class="highlight">{{count}}</span>
</div></my-code-only>
<my-show-only><div>
  カウンター：<span class="highlight">{{count}}</span>
</div></my-show-only>

<div><my-code-only>
  &lt;button class="btn btn-white" @click="incrementOfUseStore()"&gt;+1&lt;/button&gt;</my-code-only>
<my-show-only>
  <button class="btn btn-white" @click="incrementOfUseStore()">+1</button>
</my-show-only>

</div>
</pre>
        </template>
        <template #script>
<pre>
{{commonUseStore}}

{{codeIncrementOfUseStore}}
</pre>
        </template>
      </ExampleTemp>

      <PdfNextPage />
      <!-- --------------------
        ノート
      -------------------- -->
      <div id="note" class="note">
        <h4 class="text-center">ノート</h4>
        <ul class="h2-list">
          <li>
            三項演算子とは
            <div>
            三項演算子（さんこうえんざんし）とは、プログラミングにおいて条件分岐を簡潔に記述するための構文です。通常、次のような形式で表現されます：<br />
            <br />
            条件式 ? 真の場合の値 : 偽の場合の値<br />
            <br />
            この構文を使うと、条件が真の場合には最初の値が、偽の場合には二番目の値が返されます。例えば、年齢に基づいて「成人」か「未成年」を判定する場合、次のように書けます：<br />
            <br />
            <Code>
<pre>
const age = 27;
const result = age >= 18 ? "成人" : "未成年";
console.log(result)
// → 成人
</pre>
            </Code>
            <br />
            この例では、ageが18以上ならresultに「成人」が、そうでなければ「未成年」が代入されます。<br />
            三項演算子は、if-else文の省略形として使われ、コードを短くし、可読性を向上させるのに役立ちます。ただし、複雑な条件や多くの分岐がある場合は、かえって可読性が低下することもあるので、適切な使用が重要です。<br />
            <br />
            </div>
          </li>
          <li>
            Null合体演算子とは
            <div>
            Null合体演算子（nullish coalescing operator）を使用して、nullまたはundefinedの値を簡単に処理できます。この演算子は「??」で表され、左辺がnullまたはundefinedの場合に右辺の値を返し、それ以外の場合は左辺の値を返します。<br />
            <br />
            条件式(真の場合の値) ?? 偽の場合の値<br />
            <br />
            <Code>
<pre>
let name = null;
const result = name ?? "匿名";
console.log(result)
// → 匿名

name = undefined;
const result = name ?? "匿名";
console.log(result)
// → 匿名

name = "花子";
const result = name ?? "匿名";
console.log(result)
// → 花子
</pre>
            </Code>
            <br />
            この例ではnameがnullまたはundefinedの場合「匿名」が代入され、nameが文字列の場合、「花子」が代入されます。
            Null合体演算子を使うことで、デフォルト値を簡単に設定でき、コードの可読性と安全性を向上させることができます。
            </div>
          </li>

        </ul>
      </div>

    </div><!-- .examples -->

</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import PdfNextPage from "@/components/PdfNextPage.vue";
import Marker from "@/components/MarkerComponent.vue";
import Child from "@/components/sample/ChildComponentOfDefineProps.vue";
import Code from "@/components/CodeComponent.vue";
import { stringCode2scriptCode } from "@/utils";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const store = useStore();
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const router = useRouter();

const list = [
  {
    title: "関数",
    list: [
      {
        id: "defineProps",
        title: "親から渡されたプロパティ（props）を定義",
        description: "コンポーネントが受け取るプロパティ（props）を明示的に定義および取得し、型チェックやデフォルト値の設定も行える関数",
      }, {
        id: "ref",
        title: "リアクティブなデータを扱うための関数",
        description: "refを使うことで、単一の値をリアクティブにすることができます。リアクティブな値とは、値が変更されたときに自動的にUIが更新される値のことです。①でrefメソッドをインポートしたあと、②でデータオブジェクトの宣言をします。その際、refメソッドで修飾することでリアクティブな状態になります。リアクティブとは値の変化を監視するための機能です。リアクティブ化された変数のことをRef変数と呼びます。Ref変数を&lt;template&gt;内で使うと値が更新された際、自動で変更されます。const, let, varで作られた変数は自動で変更されないので注意が必要です。",
      }, {
        id: "useRouter",
        title: "Routerのインスタンスにアクセスするための関数",
        description: `<p>この関数を使うことで、プログラム的にルートの遷移を管理したり、ナビゲーションガードを設定したりすることができます。例えば、ホームページに遷移する場合は以下のように使います。またページの移動には&lt;router-link&gt;要素も使えます。router.push()は&lt;script&gt;要素内に記述された複雑なコード内での移動、&lt;router-link&gt;は&lt;template&gt;要素内で簡易的に移動するのが一般的です。</p>
          <small>パス情報に指定できる主なパラメータ</small>
          <dl class="description-option">
            <dt>params ... ルートパラメーター</dt>
            <dt>query ... クエリ情報</dt>
            <dt>hash ... ハッシュ情報</dt>
            <dt>meta ... メタ情報</dt>
          </dl>
          ※ こちらの例は、手順１です。手順２は<a href="#useRoute">&lt;useRoute&gt;</a>を参照ください。<br />`
      }, {
        id: "useRoute",
        title: "現在のアクティブなルートに関する情報を取得するための関数",
        description: `<p>この関数を使うことで、現在のURLのパス、ルートパラメータ、クエリパラメータなどにアクセスできます。</p>
          <dl class="description-option">
            <dt>params ... ルートパラメータ</dt>
            <dd>
              route.params.id
              → 0001
            </dd>
            <dt>params ... クエリ情報</dt>
            <dd>
              route.query.name
              → taro
            </dd>
            <dt>path ... ルートのパス</dt>
            <dd>
              route.path
              → /javascript/0001
            </dd>
            <dt>fullPath ... クエリ/ハッシュを含んだ完全なパス</dt>
            <dd>
              route.fullPath
              → /javascript/0001#hash?num=1
            </dd>
          </dl>
          例えば、現在のルートからパラメータまたはクエリパラメータを取得する場合は以下のように使います。<br />
          ※ こちらの例は、手順２です。手順１は<a class="a" href="#useRouter">&lt;useRouter&gt;</a>を参照ください。`,
      }, {
        id: "useStore",
        title: "Vuexストアにアクセスするための関数",
        description: "Vue.jsのストア（store）は、アプリケーション全体の状態（state）を管理するための集中型のコンテナです。Vuexというライブラリを使って実装されることが一般的です。ストアを使うことで、コンポーネント間で状態を共有しやすくなり、アプリケーションの状態管理がシンプルかつ予測可能になります。Vuexそのものは簡易的なストアにすぎません、容れ物を準備してそこに値を出し入れするだけのライブラリです。この例では、useStoreを使ってストアにアクセスし、countというステートとincrementというミューテーションを取得して変数に設定しています。useStoreを使うことで、Vuexストアのステートやゲッター、ミューテーション、アクションに簡単にアクセスでき、Composition APIの利点を活かした柔軟な状態管理が可能になります。",
      }
    ],
  }
]


const functions = {};

// --------------------
//  defineProps
// --------------------
const commonDefineProps = `<my-hl>import { defineProps } from 'vue';</my-hl>
<my-hl>const props = defineProps({
  presentToChild: { type: String, default: "あめ" }
});<my-hl>`;


// --------------------
//  ref
// --------------------
// ↓ ↓ ↓ 下からコピペ
const refCode = `<my-hl>import { ref } from "vue"; // ① refメソッドをインポート</my-hl>
<my-hl>const refObj = ref(""); // ② Refオブジェクトを作成(空の文字列で初期化)</my-hl>`;
// ↑ ↑ ↑ 下からコピペ
// ↓ ↓ ↓ コメントアウトを消して、上にコピペ
// import { ref } from "vue";
const refObj = ref("");
// ↑ ↑ ↑ コメントアウトを消して、上にコピペ

// --------------------
//  useRouter
// --------------------
// ↓ ↓ ↓ 下からコピペ
const commonUseRouter = `<Marker>// ① useRouterメソッドをインポート
import { useRouter } from 'vue-router';
// ② Routerオブジェクトを作成
const router = useRouter();
</Marker>`;
// ↑ ↑ ↑ 下からコピペ
// ↓ ↓ ↓ コメントアウトを消して、上にコピペ
// import { useRouter } from 'vue-router';
// const router = useRouter();
// ↑ ↑ ↑ コメントアウトを消して、上にコピペ

// function gotoGirlFriendFromParams() {
//   router.push({ name: 'Girlfriend', params: { present: 'ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券' } });
// }
// function gotoGirlFriendFromQuery() {
//   router.push({ name: 'Girlfriend', query: { present: 'ディズニーシー ホテル(ミラコスタ) 無料宿泊券' } });
// }

const codeGotoHomeOfUseRouter = `function gotoHomeOfUseRouter() {
<my-hl>  // ③ Homeページxに遷移
  router.push('/home');</my-hl>
}`;
eval(`functions.gotoHomeOfUseRouter = ${stringCode2scriptCode(codeGotoHomeOfUseRouter)};`);
const gotoHomeOfUseRouter = functions.gotoHomeOfUseRouter;

const codeGotoGirlFromParamsOfUseRouter = `function gotoGirlFriendFromParamsOfUseRouter() {
<my-hl>  // ③ Girlfriendページにパラメータを設定して遷移
  // http://localhost:8080/girlfriend/ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券
router.push({
    name: 'Girlfriend',
    params: { present: 'ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券'
  }});</my-hl>
}`;
eval(`functions.gotoGirlFriendFromParamsOfUseRouter = ${stringCode2scriptCode(codeGotoGirlFromParamsOfUseRouter)};`);
const gotoGirlFriendFromParamsOfUseRouter = functions.gotoGirlFriendFromParamsOfUseRouter;

const codeGotoGirlFromQueryOfUseRouter = `function gotoGirlFriendFromQueryOfUseRouter() {
<my-hl>  // ③ Girlfriendページにクエリを付けて遷移
  // http://localhost:8080/girlfriend?present=ディズニーシー ホテル(ミラコスタ) 無料宿泊券
router.push({
    name: 'Girlfriend',
    query: { present: 'ディズニーシー ホテル(ミラコスタ) 無料宿泊券' }
  });</my-hl>
}`;
eval(`functions.gotoGirlFriendFromQueryOfUseRouter = ${stringCode2scriptCode(codeGotoGirlFromQueryOfUseRouter)};`);
const gotoGirlFriendFromQueryOfUseRouter = functions.gotoGirlFriendFromQueryOfUseRouter;


// --------------------
//  useStore
// --------------------
// ↓ ↓ ↓ 下からコピペ
const commonUseStore = `import { ref } from "vue";
<my-hl>// ① useStoreメソッドをインポート
import { useStore } from 'vuex';
// ② storeオブジェクトを作成
const store = useStore();
// ③ refオブジェクトを作成(初期値にstoreの値を代入)
const count = ref(store.getters.getCount);</my-hl>`;
// ↑ ↑ ↑ 下からコピペ
// ↓ ↓ ↓ コメントアウトを消して、上へコピペ
// import { ref } from "vue";
// import { useStore } from 'vuex';
// const store = useStore();
const count = ref(store.getters.getCount);
// ↑ ↑ ↑ コメントアウトを消して、上へコピペ

const codeIncrementOfUseStore = `<my-hl>// ④ カウンターボタンがクリックされた際実行される関数を準備
function incrementOfUseStore() {
  // ④ commitメソッドを使ってstoreに反映
  store.commit('increment');
  // ⑤ Refオブジェクトにも同期しテンプレートにリアルタイムに反映
  count.value++;
}</my-hl>`;
eval(`functions.incrementOfUseStore = ${stringCode2scriptCode(codeIncrementOfUseStore)};`);
const incrementOfUseStore = functions.incrementOfUseStore;
</script>
