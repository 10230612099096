import { createStore } from 'vuex'

export default createStore({
  state: {
    count: 0,
  },
  getters: {
    getCount(state) {
      return state.count;
    },
  },
  mutations: {
    // ※ mutationsを呼んだ場合
    // increment(state, newCount) {
    increment(state) { state.count++; },
    // → store.commit('increment');
  },
  actions: {
    // increment: ({ commit }) => commit('increment'),
    // → store.dispatch('increment');
  },
  modules: {
  }
})
